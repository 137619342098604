import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/ui/Button/Button';
import Dialog from '@/components/ui/Dialog/Dialog';

import {
  B2bImage,
  ButtonWrapper,
  DialogContent,
  IconWrapper,
  Text,
} from './B2B.style';

/**
 * B2B modal component.
 */
const B2B = () => {
  const [isClosed, setIsClosed] = useState(true);

  useEffect(() => {
    const showModal = localStorage.getItem('B2BModal:isClosed') !== 'true';
    if (showModal) {
      setIsClosed(false);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('B2BModal:isClosed', 'true');
    setIsClosed(true);
  };

  if (isClosed) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={!isClosed}
        mobileMenu={false}
        visiblePhone
        PaperProps={{
          sx: {
            maxWidth: 506,
            height: 500,
          },
        }}>
        <DialogContent>
          <IconWrapper>
            <B2bImage />
          </IconWrapper>
          <Text>
            <FormattedMessage id="b2b_text" />
          </Text>
          <ButtonWrapper>
            <Button
              size="large"
              data-cy="button: closeB2B"
              fullWidth
              onClick={handleClose}>
              <FormattedMessage id="b2b_cta" />
            </Button>
          </ButtonWrapper>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default B2B;
